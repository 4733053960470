var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "header-back" },
        [
          _c(
            "a-button",
            {
              attrs: { prefix: "left" },
              on: {
                click: function ($event) {
                  return _vm.$emit("back")
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c(
        "appForm",
        [
          _c(
            "template",
            { slot: "customButton" },
            [
              _c(
                "a-button",
                {
                  attrs: { disabled: _vm.startUp },
                  on: { click: _vm.selectAll },
                },
                [_vm._v("全部选中")]
              ),
              _c(
                "a-button",
                {
                  staticClass: "top_button",
                  attrs: { disabled: _vm.startUp },
                  on: { click: _vm.deselectAll },
                },
                [_vm._v("取消全选")]
              ),
              _c(
                "a-button",
                {
                  staticClass: "top_button",
                  attrs: { type: "primary", disabled: _vm.startUp },
                  on: { click: _vm.startUpDeclare },
                },
                [_vm._v("启动申报机器人")]
              ),
              _c(
                "a-button",
                {
                  staticClass: "top_button",
                  attrs: { disabled: !_vm.startUp },
                  on: { click: _vm.terminationDeclare },
                },
                [_vm._v("终止执行")]
              ),
              _c(
                "a-button",
                {
                  staticClass: "top_button",
                  on: { click: _vm.refreshResults },
                },
                [_vm._v("刷新执行结果")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("custom-table", {
        attrs: {
          bordered: "",
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "onJobId",
          scroll: { y: 680 },
          pagination: false,
          loading: _vm.loading,
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "checkStatus",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "div",
                  [
                    _c("a-checkbox", {
                      attrs: { disabled: _vm.startUp },
                      model: {
                        value: scope.record.checkStatus,
                        callback: function ($$v) {
                          _vm.$set(scope.record, "checkStatus", $$v)
                        },
                        expression: "scope.record.checkStatus",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }